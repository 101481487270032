<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">申请添加好友</div>
      <div class="topRight"></div>
    </div>
    <div class="setUp">
      <img src="../../image/quanz/x1.png" alt="" />
      <input type="text" placeholder="设置备注和昵称" v-model="notes"/>
    </div>
    <div class="sendingBox">
      <div>发送添加好友申请</div>
      <textarea placeholder="请输入..." v-model="wording"></textarea>
    </div>
    <div class="footerBtn" @click="addFriend()">发送申请</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notes: '',
      wording: '',
      userId: '',   // 当前要添加好友的用户id
      timReady: false,
      nick: ''
    };
  },
  mounted() {
    this.userId = this.$route.query.userId;
    let user = this.$store.state.user;
    if (user.circleAuth == 2) {
      if (!this.tim.isReady()) {
        // SDK 进入 ready 状态时触发，接入侧监听此事件，然后可调用 SDK 发送消息等 API，使用 SDK 的各项功能。
        let onSdkReady = function(event) {
          this.getUser();
        };
        this.tim.on(this.Tim.EVENT.SDK_READY, onSdkReady, this);
        //SDK 进入 not ready 状态时触发，此时接入侧将无法使用 SDK 发送消息等功能。如果想恢复使用，接入侧需调用 login 接口，驱动 SDK 进入 ready 状态。
        let onSdkNotReady = function(event) {
          this.imLogin();
        };
        this.tim.on(this.Tim.EVENT.SDK_NOT_READY, onSdkNotReady, this);
        this.imLogin();
      } else {

      }
    }
  },
  destroyed() {
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 发送申请
    sendEvent() {
      this.$router.go(-1);
    },
    imLogin() {
      let user = this.$store.state.user;
      if (user.circleAuth == 2 && !this.timReady) {
        let loginPromise = this.tim.login({userID: user.id + "", userSig: user.imSig});
        loginPromise.then(function (imResponse) {
          if (imResponse.data.repeatLogin === true) {
            // 标识账号已登录，本次登录操作为重复登录。
            console.log(imResponse.data.errorInfo);
          }
        }).catch(function (imError) {
          console.warn('login error:', imError); // 登录失败的相关信息
        });
      }
    },
    // 查看用户信息
    getUser() {
      let promise = this.tim.getUserProfile({
        userIDList: [this.userId + ""] // 请注意：即使只拉取一个用户的资料，也需要用数组类型，例如：userIDList: ['user1']
      });
      let tis = this;
      promise.then(function(imResponse) {
        let userProfile = imResponse.data[0];
        tis.nick = userProfile.nick;
      }).catch(function(imError) {
        console.warn('getUserProfile error:', imError); // 获取其他用户资料失败的相关信息
      });
    },
    addFriend() {
      if (!this.tim.isReady()) {
        return;
      }
      if (!this.notes) {
        this.notes = this.nick;
      }
      // 添加好友
      let promise = this.tim.addFriend({
        to: this.userId + "",
        source: 'AddSource_Type_Web',
        remark: this.notes,
        groupName: '好友',
        wording: this.wording,
        type: this.Tim.TYPES.SNS_ADD_TYPE_BOTH,
      });
      promise.then(function(imResponse) {
        // 添加好友的请求发送成功
        const { code } = imResponse.data;
        console.log(imResponse)
        if (code === 30539) {
          // 30539 说明 user1 设置了【需要经过自己确认对方才能添加自己为好友】
          // 此时 SDK 会触发 TencentCloudChat.EVENT.FRIEND_APPLICATION_LIST_UPDATED 事件
        } else if (code === 0) {
          // 0 说明 user1 设置了【允许任何人添加自己为好友】
          // 此时 SDK 会触发 TencentCloudChat.EVENT.FRIEND_LIST_UPDATED 事件
        }
      }).catch(function(imError) {
        console.warn('addFriend error:', imError); // 添加好友失败的相关信息
      });
      this.$router.go(-1);
    }
  },
};
</script>

<style scoped>
.boxTop {
  width: 750px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.topRight {
  margin-right: 32px;
}
.setUp {
  width: 686px;
  height: 104px;
  border-bottom: 1px solid #f6f8fa;
  margin-left: 32px;
  margin-top: 24px;
  display: flex;
  align-items: center;
}
.setUp img {
  width: 32px;
  height: 32px;
}

.setUp input {
  width: 400px;
  height: 50px;
  font-size: 28px;
  border: none;
  color: #97a4b4;
  margin-left: 4px;
}

.sendingBox {
  width: 686px;
  height: 324px;
  margin-left: 32px;
  border-bottom: 1px solid #f6f8fa;
}
.sendingBox div {
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 32px;
}
.sendingBox textarea {
  width: 686px;
  height: 200px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: none;
  margin-top: 20px;
  color: #97a4b4;
  font-size: 28px;
  padding: 24px;
  box-sizing: border-box;
}
.footerBtn {
  width: 686px;
  height: 84px;
  background: #0957c3;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 84px;
  position: fixed;
  bottom: 60px;
  left: 32px;
}
</style>
